/* Animations */
@keyframes TOSPop {
    0% {transform: scale(0.98); opacity:50%;}
    100% {transform: scale(1); opacity:100%;}
}


.TOSContainer{
    width: 850px;
    margin: auto;    
    font-family: 'Inter', sans-serif;
}

.TOSBubble{
    animation: Four04Pop 0.2s forwards;
    opacity: 0%;
    background-color: #252632;
    margin-top: 40px;
    padding: 30px;
    border-radius: 20px;
    margin-bottom: 200px;
}

.TOSTitle{
    color: white;
    font-weight: 600;
    text-align: center;
    font-size: 25px;
}

.TOSSubTitle{
    color: white;
    font-weight: 500;
    text-align: center;
    font-size: 14px;
}

.TOSText{
    padding-top: 15px;
    color: white;
    padding-bottom: 10px;
}

#TOSLink{
    color: #D6BCFA;
}

#TOSLink:hover{
    text-decoration: underline;
}

/* Phones */
@media only screen and (max-device-width: 435px) {
    .TOSContainer{
        width: 93vw;
        margin: auto;    
        font-family: 'Inter', sans-serif;
    }
    .TOSTitle{
        font-size: 23px;
    }
}